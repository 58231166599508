import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";


class ContactInfo extends Component {
    render() {
        return (
            <section className="contact-page-wrap section-padding">
                <Grid container spacing={3} className="contact-deatils websitePadding">
                    <Grid item xs={12} sm={4}>
                        <Grid className="single-contact-details">
                            <h3>Zadzwoń do nas</h3>
                            <p>+48 723 194 999</p>
                            <p>+48 781 999 941</p>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid className="single-contact-details">
                            <h3>Adres</h3>
                            <p>Ostroszowicka 11 <br/> 58-260 Bielawa</p>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid className="single-contact-details">
                            <h3>Email</h3>
                            <p>dsi.slosarz@gmail.com</p>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default ContactInfo