import React, { Component } from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import WhatDoLeftImage from '../../../images/faq1.png';

const useStyles = makeStyles({
    root: {
        color: '#ff5e10',
        textDecoration: 'none',
        fontFamily: "Arial",
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

const StyledLink = (props) => {
    const classes = useStyles();

    return (
        <Link className={classes.root} to={props.to}>
            {props.children}
        </Link>
    )
};

class FAQ extends Component {

    state = {
        open1: true,
        open2: false,
        open3: false,
    }

    handleClick1 = () => {
        this.setState({
            open1: !this.state.open1
        })
    };

    handleClick2 = () => {
        this.setState({
            open2: !this.state.open2
        })
    };
    handleClick3 = () => {
        this.setState({
            open3: !this.state.open3
        })
    };

    render() {

        return (


            <section className="fq-ask-wrap section-padding">
                <Grid container spacing={3} className="websitePadding">
                    <Grid item xs={12} md={6} sm={12}>
                        <Grid className="fq-ask-banner">
                            <img src={WhatDoLeftImage} alt=""/>
                        </Grid>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <Grid className="theme-heading">
                            <span>FAQ</span>
                            <h2>Zadawane pytania</h2>
                        </Grid>
                        <Grid className="frequent-ask-accrodian">
                            <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"

                                className={'root'}
                            >

                                <ListItem className="collapse_heading" button onClick={this.handleClick1}>
                                    <ListItemText primary="Czy wykonujecie idywidualne kształty?"/>
                                    {this.state.open1 ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={this.state.open1} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className={'nested'}>
                                            <ListItemText className="collapse_description" primary="Tak, oferujemy usługi gięcia drutu gładkiego i żebrowanego według indywidualnych potrzeb i wymagań naszych klientów. Jesteśmy w stanie dostosować nasze usługi do tworzenia unikalnych kształtów i struktur, aby spełnić oczekiwania każdego projektu."/>
                                        </ListItem>
                                    </List>
                                </Collapse>
                                <ListItem className="collapse_heading" button onClick={this.handleClick2}>
                                    <ListItemText primary="Jakie są terminy realizacji?"/>
                                    {this.state.open2 ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={this.state.open2} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className={'nested'}>
                                            <ListItemText className="collapse_description">
                                                <Typography style={{ color: 'rgb(86,89,105)' }}>
                                                    Terminy realizacji są zawsze ustalane indywidualnie z klientem, w zależności od skomplikowania projektu i obecnego obciążenia naszych zasobów. Zawsze staramy się, aby nasze usługi były realizowane jak najszybciej, nie rezygnując przy tym z najwyższych standardów jakości. Prosimy o <StyledLink to="/contact">kontakt</StyledLink>, abyśmy mogli omówić szczegóły i dostosować termin do Twoich potrzeb.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Collapse>
                                <ListItem className="collapse_heading" button onClick={this.handleClick3}>
                                    <ListItemText primary="Jaki jest koszt gięcia drutu?"/>
                                    {this.state.open3 ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={this.state.open3} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className={'nested'}>
                                            <ListItemText className="collapse_description">
                                                <Typography style={{ color: 'rgb(86,89,105)' }}>
                                                    Koszty gięcia drutu są ustalane indywidualnie, w zależności od specyfiki projektu - rodzaju drutu, jego średnicy, ilości gięć i stopnia skomplikowania. Dbamy o to, aby nasze ceny były konkurencyjne, jednocześnie oferując usługi najwyższej jakości. Zapraszamy do <StyledLink to="/contact">kontaktu</StyledLink>, aby omówić szczegóły i otrzymać precyzyjną wycenę.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        )
    }

}

export default FAQ;