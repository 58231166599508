import React, {Component} from "react";
import Header from "../Home1/Header";
import HeroArea from "../Banner/index.js";
import ContactInfo from "./ContactInfo";
import ContactMap from "./ContactMap";
import ContactBackground from "../../images/banner/contact.png";

class ContactPage extends Component{
    render() {
        return(
            <div>
                <Header/>
                <HeroArea image={ContactBackground}/>
                <ContactInfo/>
                <ContactMap/>
            </div>
        )
    }
}
export default ContactPage;