import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";

class ContactMap extends Component {
    render() {
        return (
            <section className="google-map-wrap">
                <Grid container spacing={3} class="websitePadding">
                    <Grid item lg={12} class="">
                        <Grid class="gmap">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.603548065979!2d16.6078!3d50.672777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470e37e36704ad9d%3A0x3d016ebdb0ea98d2!2sAlmet+Pro+Sp.+Z+O.o.%2C+Ostroszowicka+11%2C+58-260+Bielawa!5e0!3m2!1sen!2sbd!4v1625619640232!5m2!1sen!2sbd"
                                title="Google Map of Almet Pro Sp. Z O.o., Bielawa"
                                allowFullScreen>
                            </iframe>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default ContactMap;