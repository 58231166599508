import React, {Component} from "react";
import Header from "../Home1/Header";
import HeroArea from "../Banner/index.js";
import OurPortfolio from "../Home1/OurPortfolio";
import ProductsBackground from "../../images/banner/products.png";

class PortfolioPage extends Component{
    render() {
        return(
            <div>
                <Header/>
                <HeroArea image={ProductsBackground}/>
                <OurPortfolio/>
            </div>
        )

    }
}
export default PortfolioPage;