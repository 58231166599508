import React, {Component} from "react";
import { Link } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import Hidden from "@material-ui/core/Hidden";

const menus = [
    {
        id: 1,
        title: 'Strona domowa',
        link: '/'
    },
    {
        id: 2,
        title: 'O nas',
        link: '/about'
    },
    {
        id: 4,
        title: 'Usługi',
        link: '/services'
    },
    {
        id: 6,
        title: 'Produkty',
        link: '/portfolio'
    },
    {
        id: 8,
        title: 'Kontakt',
        link: '/contact'
    },
];

class Nav extends Component {

    state = {
        active_menu: 0,
        menu_show: false
    };

    submenuHandler = id => () => {
        this.setState({
            active_menu: id === this.state.active_menu ? 0 : id
        })
    };

    mobileMenuHandler = () => {
        this.setState({
            menu_show: !this.state.menu_show
        })
    }

    render(props) {
        var NavURL = window.location.pathname;
        const {active_menu, menu_show} = this.state;
        return (
            <div>
                <div className="mobile-menu-item">
                    <i id="mobile_menu_icon" className="fas fa-align-justify" onClick={this.mobileMenuHandler}></i>
                    <Hidden lgUp>
                        <Collapse in={menu_show} timeout="auto" unmountOnExit>
                            <nav className={NavURL ? '' : ''}>
                                <ul className="menu-items">
                                    {menus.map(item => (
                                        <li key={item.id}>
                                            <Link onClick={this.submenuHandler(item.id)} to={item.link}>{item.title}
                                                {item.submenu ? <i className={`far fa-${active_menu === item.id ? 'minus' : 'plus' }-square submenu-icon`}></i> : null}
                                            </Link>

                                            <Collapse in={active_menu === item.id} timeout="auto" unmountOnExit>
                                                {item.submenu ?
                                                    <ul className="submenu">
                                                        {item.submenu.map(submenu => (
                                                            <li key={submenu.id}><Link
                                                                to={submenu.link}>{submenu.title}</Link></li>
                                                        ))}
                                                    </ul> : null}
                                            </Collapse>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </Collapse>
                    </Hidden>
                </div>
                <Hidden mdDown>
                    <nav id="responsive-menu" className={NavURL ? 'menu-style-one menu-two' : 'menu-style-one'}>
                        <ul className="menu-items">
                            <li><Link to="/">Strona domowa</Link></li>
                            <li><Link to="/about">O nas</Link></li>
                            <li><Link to="/services">Usługi</Link></li>
                            <li><Link to="/portfolio">Produkty</Link></li>
                            <li style={{ marginLeft: '40px' }}><Link to="/contact" className="theme_btn get_start" style={{ color: 'white' }}>KONTAKT</Link></li>
                        </ul>
                    </nav>
                </Hidden>
            </div>
        )
    }
}

export default Nav;