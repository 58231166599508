import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderImage1 from '../../../images/slide/hala.png';
import {Link} from "react-router-dom";

class HeroSlider extends Component {
    render() {
        var settings = {
            dots: false,
            autoplay: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <Slider {...settings}>
                <section className="hero-slider-area">
                    <Grid className="hero-slides owl-carousel">
                        <Grid className="single-hero-slide bg-cover bg-center text-white dark-overlay d-flex align-items-center"
                              style={{backgroundImage: `url(${SliderImage1})`}}>
                            <Grid container spacing={3} className="sLider-grid-item customGridwidth websitePadding">
                                <Grid item lg={12} xs={12} md={12} style={{ display: "flex", flexDirection: "column", gap: "16px", justifyContent: "left"}}>
                                    <span>PRODUKCJA WYROBÓW Z DRUTU</span>
                                    <h1>GIĘCIE DRUTU - szybkie terminy realizacji</h1>
                                    <p style={{ maxWidth: "550px" }}>Jesteśmy producentem wyrobów górniczych tj. żerdzie z pręta żebrowanego jak i gładkiego oraz różnego rodzaju podkładek tłoczonych z blachy do KGHM Polska Miedź. <br/> <br/> Almet - Lider w branży gięcia drutu gładkiego i żebrowanego oraz spawania i zgrzewania. Nasza firma, z siedzibą w Bielawie, z dumą służy klientom z całej Polski, dostarczając im usługi najwyższej jakości.</p>
                                    <Link to="/services" className="theme_btn get_start">NASZE PRODUKTY</Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
            </Slider>
        );
    }
}

export default HeroSlider;