import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";

class CallToAction extends Component {
    render() {
        return(
            <section className="call-to-action-wrap section-padding text-white bg-cover bg-bottom">
                <Grid container spacing={3} className="websitePadding">
                    <Grid item lg={2}></Grid>
                    <Grid item md={12} lg={8} className="col-md-12 col-lg-8 offset-lg-2 text-center">
                        <Grid className="call-to-action">
                            <h2>Twórzmy razem, niezwykłe rzeczy</h2>
                            <p>Jesteś zainteresowany naszymi usługami? Chcesz dowiedzieć się więcej o tym, co możemy zrobić dla Ciebie? Skontaktuj się z nami! Nasz zespół chętnie odpowie na wszelkie pytania, omówi szczegóły i pomoże Ci w realizacji Twojego projektu.</p>
                            <Link to="/contact" className="theme_btn call-action-btn" style={{ marginTop: "20px" }}>POROZMAWIAJ Z NAMI</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        )
    }
}
export default CallToAction;