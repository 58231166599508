import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Time from '../../../images/characteristics/time.png';
import Accuracy from '../../../images/characteristics/accuracy.png';
import Passion from '../../../images/characteristics/passion.png';
import Price from '../../../images/characteristics/price.png';

class WhatWeDo extends Component {
    render() {
        return (
            <section className="what-we-do section-padding websitePadding" style={{ paddingBottom: "0px"}} id="whatwedo">
                <Grid container spacing={3}>
                    <Grid item lg={1} xs={12}></Grid>
                    <Grid item lg={10} xs={12} className="offset-lg-1 col-12 text-center">
                        <Grid className="what-we-do-title">
                            <span>co nas wyróżnia</span>
                            <h2>Kluczowe powody, dla których Almet jest Twoim idealnym partnerem w
                                <span> gięciu drutu i spawaniu:</span>
                            </h2>
                        </Grid>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12} className="text-center">
                        <Grid className="single-we-do-home">
                            <img src={Time} alt="time"/>
                            <h3>Terminowość</h3>
                            <p>Zawsze dotrzymujemy terminów, bo wiemy, jak ważne jest to dla naszych klientów.</p>
                        </Grid>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12} className="text-center">
                        <Grid className="single-we-do-home">
                            <img src={Accuracy} alt="accuracy"/>
                            <h3>Dokładność</h3>
                            <p>Nasze usługi charakteryzują się najwyższą precyzją, co pozwala nam dostarczać produkty spełniające najwyższe standardy.</p>
                        </Grid>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12} className="text-center">
                        <Grid className="single-we-do-home">
                            <img src={Passion} alt="passion"/>
                            <h3>Pasja</h3>
                            <p>Kochamy to, co robimy, a nasza pasja przekłada się na jakość naszych usług.</p>
                        </Grid>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12} className="text-center">
                        <Grid className="single-we-do-home">
                            <img src={Price} alt="price"/>
                            <h3>Konkurencyjne ceny</h3>
                            <p>Oferujemy usługi najwyższej jakości w konkurencyjnych cenach.</p>
                        </Grid>
                    </Grid>
                </Grid>
            </section>

        )
    }
}

export default WhatWeDo;