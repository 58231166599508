import React, {Component} from "react";
import Header from "../Home1/Header";
import HeroArea from "../Banner/index.js";
import AboutFeatured from "./AboutFeatured";
import FAQ from "../Home1/FAQ";
import AbousUsBackground from "../../images/banner/about_us.png";

class aboutPage extends Component{
    render() {
        return(
            <div>
                <Header/>
                <HeroArea image={AbousUsBackground}/>
                <AboutFeatured/>
                <FAQ/>
            </div>
        )
    }
}
export default aboutPage;