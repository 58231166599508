import React, { Component } from "react";
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";

class HeroArea extends Component {
    render() {
        var settings = {
            dots: false,
            autoplay: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <Slider {...settings}>
                <section className="hero-slider-area">
                    <Grid className="hero-slides owl-carousel">
                        <Grid
                            className="single-hero-slide bg-cover bg-center text-white dark-overlay d-flex align-items-center"
                            style={{backgroundImage: `url(${this.props.image})`}}>
                        </Grid>
                    </Grid>
                </section>
            </Slider>
        );
    }
}

export default HeroArea;
