import React, {Component} from "react";
import OurService1 from '../../../images/characteristics/time.png';
import OurService2 from '../../../images/characteristics/accuracy.png';
import OurService3 from '../../../images/characteristics/passion.png';
import OurService4 from '../../../images/characteristics/price.png';
import Grid from "@material-ui/core/Grid";

const ServiceItem = [
    {
        id: 1,
        title: 'Produkcja Zawieszek',
        image: OurService1,
        description: 'Nasza firma specjalizuje się w profesjonalnej produkcji wysokiej jakości zawieszek. Dzięki naszemu doświadczeniu i zaawansowanym technologiom, dostarczamy klientom precyzyjnie wykonane i trwałe zawieszki, idealne do różnych zastosowań.',
    },
    {
        id: 2,
        title: 'Produkcja Przegród',
        image: OurService2,
        description: 'Oferujemy kompleksową produkcję przegród o najwyższych standardach jakości. Nasze przegrody są starannie wykonane, trwałe i funkcjonalne. Dostosowujemy się do indywidualnych potrzeb i zapewniamy szybką realizację zamówień.',
    },
    {
        id: 3,
        title: 'Produkcja Zbrojeń',
        image: OurService3,
        description: 'Jesteśmy liderem w branży produkcji zbrojeń. Nasze zbrojenia spełniają najwyższe standardy jakości i są wytrzymałe, zapewniając solidne wzmocnienie konstrukcji. Zaufało nam już wielu klientów zadowolonych z naszych usług.',
    },
    {
        id: 4,
        title: 'Produkacja Półek Drucianych',
        image: OurService4,
        description: 'Specjalizujemy się w profesjonalnej produkcji półek drucianych. Nasze półki są wyjątkowo trwałe, łatwe w montażu i estetyczne. Dostarczamy rozwiązania, które doskonale sprawdzają się w różnych przestrzeniach i branżach.',
    },
    {
        id: 5,
        title: 'Produkacja Kotew Drucianych',
        image: OurService1,
        description: 'Zajmujemy się profesjonalną produkcją kotew drucianych. Nasze kotwy są solidne, wytrzymałe i zapewniają pewne mocowanie. Dostarczamy klientom kotwy o różnych rozmiarach i specyfikacjach, spełniające najwyższe wymagania.',
    },
    {
        id: 6,
        title: 'Produkacja Kotew Stalowych',
        image: OurService2,
        description: 'Nasza firma specjalizuje się w produkcji kotew stalowych o najwyższej jakości. Nasze kotwy są niezawodne, trwałe i doskonale sprawdzają się w różnych aplikacjach budowlanych. Zapewniamy szybką realizację zamówień i konkurencyjne ceny.',
    },
    {
        id: 7,
        title: 'Indywidualna produkcja',
        image: OurService1,
        description: 'Realizujemy indywidualne zamówienia na produkcję wyrobów metalowych. Nasza firma oferuje kompleksową obsługę klienta, starając się spełnić wszystkie indywidualne potrzeby i oczekiwania. Dzięki zaawansowanym technologiom i doświadczeniu, dostarczamy produkty najwyższej jakości.',
    },
    {
        id: 8,
        title: 'Produkcja Strzemion Budowlanych',
        image: OurService4,
        description: 'Jesteśmy liderem w produkcji strzemion budowlanych. Nasze strzemiona są solidne, trwałe i wytrzymałe na obciążenia. Dostarczamy klientom wysokiej jakości produkty, które spełniają wymagania nawet najbardziej wymagających projektów budowlanych.',
    },
    {
        id: 9,
        title: 'Produkcja Żerdzi',
        image: OurService4,
        description: 'Oferujemy wysokiej jakości żerdzi drutu, które są używane w różnych aplikacjach konstrukcyjnych i ogrodzeniowych. Nasze zerdzie drutu są precyzyjnie wykonane, trwałe i wytrzymałe, zapewniając solidne wsparcie i stabilność.',
    },
    {
        id: 10,
        title: 'Barierki np. koncertowe',
        image: OurService4,
        description: 'Dzięki precyzyjnemu projektowaniu i starannej produkcji, nasze bariery koncertowe są łatwe w montażu i demontażu, co umożliwia szybkie tworzenie bezpiecznych stref dla publiczności. Nasze bariery koncertowe spełniają najwyższe standardy bezpieczeństwa i są dostępne w różnych rozmiarach i konfiguracjach.',
    },
];

class Services extends Component {
    render() {
        return (
            <section className="what-we-do section-padding">
                <Grid container spacing={3} className="websitePadding">
                    <Grid item lg={1}></Grid>
                    <Grid item lg={10} xs={12} className="text-center">
                        <Grid className="what-we-do-title">
                            <span>czym się zajmujemy</span>
                            <h2>Zamujemy się produkcją wyrobów z drutu i metalu, produkujemy m.in zbrojenia i strzemiona budowlane, nasza specjalność to <span>indywidualna produkcja.</span>
                            </h2>
                        </Grid>
                    </Grid>
                    {ServiceItem.map(service => {
                        return (
                            <Grid key={service.id} item md={3} sm={6} style={{ padding: "15px", paddingTop: "0px", paddingBottom: "0px" }}>
                                <Grid className="single-we-do">
                                    <h3>{service.title}</h3>
                                    <p>{service.description}</p>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </section>
        )
    }
}

export default Services;