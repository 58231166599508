import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import CountUp from 'react-countup';

class CounterUp extends Component {

    state = {
        isRender : false
    }

    componentDidMount() {
        window.addEventListener('scroll', event => {

            let cached = null
            setTimeout(() => {
                cached = null
            }, 0)
            cached = event
            if (window.scrollY >= "2400"){
                console.log(this.state.isRender)
                this.setState({
                    isRender: true
                })
            } else {
                this.setState({
                    isRender: false
                })
            }
        })
    }

    render() {
        return (
            <section className="funfact-countup section-padding" style={{ paddingTop: '0px'}}>
                <Grid container spacing={3} className="websitePadding right-counter-image">
                    <h1 className="counter-title">Almet <br/>w liczbach</h1>
                        <Grid item md={2} sm={6} xs={12} className="col-lg-2 col-md-6 mr-50">
                            <Grid className="single-countup">
                                <span className="counter">{ this.state.isRender ? <CountUp end={13}/> : '00'}</span>
                                Lata doświadczenia
                            </Grid>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} className="col-lg-2 col-md-6 mr-50">
                            <Grid className="single-countup">
                                <span>{ this.state.isRender ? <CountUp end={35}/> : '00'}</span>
                                Ilość maszyn
                            </Grid>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} className="col-lg-2 col-md-6 mr-50">
                            <Grid className="single-countup">
                                <span>{ this.state.isRender ? <CountUp end={52}/> : '00'}</span>
                                Ilość pracowników
                            </Grid>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} className="col-lg-2 col-md-6 mr-50">
                            <Grid className="single-countup">
                                <span>{ this.state.isRender ? <CountUp end={317}/> : '00'}</span>
                                Zadowoleni klienci
                            </Grid>
                        </Grid>
                </Grid>
            </section>
        )
    }
}

export default CounterUp;