import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import BoxImage1 from '../../../images/icons/Artboard7.svg';
import BoxImage2 from '../../../images/icons/Artboard8.svg';
import BoxImage3 from '../../../images/icons/Artboard9.svg';

class FeaturedBox extends Component {
    render() {
        return (
            <section className="features-box-items">
                <Grid container spacing={3} className="websitePadding">
                    <Grid item lg={4} md={4} sm={12} xs={12} className="">
                        <Grid className="single-feature-box-item">
                            <Grid className="item-icon">
                                <img src={BoxImage1} width={60} height={60} alt="1"/>
                            </Grid>
                            <h3>Gięcie drutu gładkiego</h3>
                            <p>Dostosowujemy drut gładki o średnicy do 8mm do wybranych kształtów, gwarantując precyzyjność i jakość naszej pracy </p>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} className="">
                        <Grid className="single-feature-box-item">
                            <Grid className="item-icon">
                                <img src={BoxImage2} width={60} height={60} alt="2"/>
                            </Grid>
                            <h3>Gięcie drutu żebrowanego</h3>
                            <p>Specjalizujemy się w gięciu drutu żebrowanego o średnicy do 8mm, tworząc kształty zgodne z wymaganiami naszych klientów </p>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} className="">
                        <Grid className="single-feature-box-item">
                            <Grid className="item-icon">
                                <img src={BoxImage3} width={60} height={60} alt="3"/>
                            </Grid>
                            <h3>Spawanie metalu</h3>
                            <p>Oferujemy usługę spawania szerokiej gamy materiałów, zawsze gwarantując najwyższą jakość i precyzyję wykonania </p>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default FeaturedBox;