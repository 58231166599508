import React, {Component} from "react";
import Header from "../Home1/Header";
import HeroArea from "../Banner/index.js";
import Services from "./Services";
import ServiceBackground from "../../images/banner/services.png";

class OurServicePage extends Component{
    state ={
        bgColor: '#f2f3f5'
    }
    render() {
        return(
            <div>
                <Header/>
                <HeroArea image={ServiceBackground}/>
                <Services/>
            </div>
        )
    }
}
export default OurServicePage;