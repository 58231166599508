import React, {Component} from "react";
import VideoImage from '../../../images/welding.png';

import Grid from "@material-ui/core/Grid";

class AboutFeatured extends Component {
    render() {
        return (
            <section className="featured-about-wrap sada-bg section-padding" style={{ paddingBottom: "0px"}}>
                <Grid container spacing={3} className="websitePadding">
                    <Grid item md={6} sm={12} xs={12} className="col-lg-6 col-md-12">
                        <Grid className="video-section-content video-section-content-1">
                            <h2 style={{ lineHeight: "60px" }}>Dlaczego wybierasz Almet?</h2>
                            <ul style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                <li>
                                    <h3>Doświadczenie i profesjonalizm</h3>
                                    <p>Jesteśmy liderem w branży gięcia drutu gładkiego i żebrowanego oraz spawania. Nasza wiedza i doświadczenie gwarantują, że każdy projekt jest realizowany z najwyższą precyzją i jakością.</p>
                                </li>
                                <li>
                                    <h3>Indywidualne podejście</h3>
                                    <p>Zawsze dostosowujemy nasze usługi do indywidualnych potrzeb klienta. Niezależnie od skomplikowania projektu, jesteśmy w stanie zrealizować Twoją wizję, tworząc unikalne i funkcjonalne konstrukcje.</p>
                                </li>
                                <li>
                                    <h3>Terminowość i konkurencyjne ceny</h3>
                                    <p>Zawsze dotrzymujemy terminów, ponieważ wiemy, jak ważne jest to dla naszych klientów. Oferujemy usługi najwyższej jakości w konkurencyjnych cenach, aby dostarczyć wartość, która przekracza koszty.</p>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} className="col-lg-5 offset-lg-1 col-md-12">
                        <Grid className="video-section-img">
                            <img src={VideoImage} alt="" style={{ maxWidth: "100%", height: "auto"}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default AboutFeatured;