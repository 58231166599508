import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import FooterCall from '../../../images/call.png';
import FooterHome from '../../../images/home.png';

class Footer extends Component {
    render() {
        return (
            <Grid>
                <section
                    className={(this.props.websiteUrl === "/services" || this.props.websiteUrl === "/portfolio") ? "contact-section text-white contact-sec-bg" : "contact-section text-white"}
                    id="contact">
                    <Grid container spacing={3} className="contact-info-bg text-white websitePadding">
                        <Grid item lg={6} sm={6} xs={12}>
                            <Grid className="single-contact-info">
                                <Grid className="contact-icon">
                                    <img src={FooterCall} alt=""/>
                                </Grid>
                                <Grid className="contact-content">
                                    <h4>Kontakt</h4>
                                    <p>Zadzwoń: +48 723 194 999 <br/> +48 781 999 941 <br/> Email: dsi.slosarz@gmail.com</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                            <Grid className="single-contact-info">
                                <Grid className="contact-icon">
                                    <img src={FooterHome} alt=""/>
                                </Grid>
                                <Grid className="contact-content">
                                    <h4>Adres</h4>
                                    <p>Ostroszowicka 11 <br/> 58-260 Bielawa</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
                <footer className="footer-section pt-150 pb-30">
                    <Grid container spacing={3} className="pb-80 websitePadding">
                        <Grid item xs={12} sm={6} className="single-footer-widget">
                            <h3>Almet</h3>
                            <p>Lider w branży gięcia drutu gładkiego i żebrowanego oraz spawania. Z siedzibą w Bielawie, z dumą służymy klientom z całej Polski, dostarczając usługi najwyższej jakości.</p>
                            <p style={{ color: "rgb(128,128,128)"}}>ALMET Oskar Ślósarz <br/> NIP: 8822131956 <br/> Ostroszowicka 11 <br/> 58-260 Bielawa</p>
                        </Grid>
                        <Grid item xs={12} sm={6} className="single-footer-widget">
                            <h3>Godziny otwarcia</h3>
                            <p>Poniedziałek – Piątek: 9:00 - 18:00</p>
                            <p>Sobota : 9:00 - 17:00</p>
                        </Grid>
                    </Grid>
                    <Grid className="footer-bootm pt-30">
                        <Grid container spacing={3} className="websitePadding">
                            <Grid item xs={12} className="footer-text">
                                <span>Copyright ©2023 Almet. Wszystkie prawa zastrzeżone</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </footer>

            </Grid>
        )
    }
}

export default Footer;