import React, {Component} from "react";
import Header from '../Home1/Header';
import HeroSlider from '../Home1/HeroSlider';
import WhatWeDo from '../Home1/WhatWeDo';
import FAQ from '../Home1/FAQ';
import CallToAction from "../Home1/CallToAction";
import Portfolio from "../Home1/OurPortfolio";
import FeaturedBox from "./FeaturesBox";
import CounterUp from "./Counter";

class HomePage extends Component{
    render() {
        return(
            <div>
                <Header/>
                <HeroSlider/>
                <FeaturedBox/>
                <WhatWeDo/>
                <FAQ/>
                <CounterUp/>
                <CallToAction/>
                <Portfolio/>
            </div>
        )
    }

}
export default HomePage;